import React from 'react'
import Url from 'components/Url'

export default {
  'RA-02-01': 'LOGIN_ERROR_MESSAGE',
  'RA-02-02': ({ translate }) => <Url {...{ text: translate('ACCOUNT_LOCKED_MESSAGE'), url: '/forgot-password' }} />,
  'RA-02-03': ({ translate }) => <Url {...{ text: translate('ACCOUNT_DEACTIVATED_MESSAGE'), url: '/contact-us' }} />,
  'RA-02-06': ({ translate }) => <Url {...{ text: translate('ACCOUNT_UPGRADED_MESSAGE'), url: '/forgot-password' }} />,
  'RA-10-04': 'LOGIN_ERROR_MESSAGE_DUPLICATE_EMAIL',
  'RA-MHR-148-02': 'SESSION_EXPIRED_ERROR_MESSAGE',
  'RA-04-02': ({ translate }) => <Url {...{ text: translate('RESET_PASSWORD_INVALID_TOKEN'), url: '/sign-in' }} />
}
