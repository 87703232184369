import errorCodes from './errorCodes'
import ClientSDK from '@redant/digital-store-client-sdk-mhr'

export const baseUrl = process.env.vigilanceHubApiUrl
const sdk = new ClientSDK({ baseUrl })
console.log(`Vigilance Hub API: ${baseUrl}`)

export const parseError = (error) => {
  if (error.code && errorCodes[error.code]) return errorCodes[error.code]
  return error.message
}

export default sdk
