import _ from 'lodash'

export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const FAIL = 'FAIL'

export default function promiseMiddleware ({ dispatch }) {
  return (next) => (action) => {
    const { promise, type, ...rest } = action

    // Pass action on if not promise
    if (!promise) {
      return next(action)
    }
    // Begin action
    function promiseThunk (dispatch, getState) {
      dispatch({ ...rest, type, status: PENDING })
      return promise(dispatch, getState)
        .then((result) => {
          // Success action
          const successAction = {
            ...rest, result, type, status: SUCCESS
          }
          dispatch(successAction)
          return Promise.resolve(result)
        })
        .catch((error) => {
          try {
            if (!_.isError(error)) {
              const currrentError = error
              error = new Error()
              _.assign(error, currrentError.error || currrentError)
            }
          } catch (err) {
            console.log(err)
          }
          dispatch({
            ...rest, error, type, status: FAIL
          })
          return Promise.reject(error)
        })
    }
    promiseThunk.interceptInOffline = true
    return next(promiseThunk)
  }
}
