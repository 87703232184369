export const LOGIN = 'auth/LOGIN'
export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const LOGOUT = 'auth/LOGOUT'
export const UPDATE_AUTH_USER = 'auth/UPDATE_AUTH_USER'
export const RESTORE = 'auth/RESTORE'
export const REGISTER = 'auth/REGISTER'
export const CHANGE_CURRENT_USER_PASSWORD = 'auth/CHANGE_CURRENT_USER_PASSWORD'
export const SET_KEEP_ME_LOGGED_IN = 'auth/SET_KEEP_ME_LOGGED_IN'
export const EDIT_WATCHLIST_ADD = 'auth/EDIT_WATCHLIST_ADD'
export const EDIT_WATCHLIST_REMOVE = 'auth/EDIT_WATCHLIST_REMOVE'
